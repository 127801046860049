import request from "@/Utils/api";
// 课程列表
export function course_list(data) {
  return request({
    url: "xapi/course.course/getList",
    method: "post",
    data
  });
}
// 试题列表
export function testListapi(data) {
  return request({
    url: "api/v1_0_0.question_list/examList",
    method: "get",
    params:data,
  });
}
// 课程筛选

export function courseConfig(data) {
  return request({
    url: "api/v1_0_0.course/config",
    method: "post",
    cache:true,
    data
  });
}


// 课程详情
export function courseDetail(data) {
  return request({
    url: "api/v2_0_0.course/detail",
    method: "post",
    data
  });
}
// 课程知识点详情
export function courseInfoList(data) {
  return request({
    url: "api/v1_0_0.course/info_list",
    method: "post",
    data
  });
}
// 学员列表
export function courseStudent(data) {
  return request({
    url: "api/v1_0_0.course/student",
    method: "post",
    data
  });
}
// 学员评论列表
export function comment_list(data) {
  return request({
    url: "api/v1_0_0.course/comment_list",
    method: "post",
    data
  });
}
// 学员评论
export function comment_submit(data) {
  return request({
    url: "api/v1_0_0.course/comment_submit",
    method: "post",
    data
  });
}
// 课程收藏
export function collect(data) {
  return request({
    url: "api/v1_0_0.course/collect",
    method: "post",
    data
  });
}
// 立即报名
export function create_order(data) {
  return request({
    url: "api/v1_0_0.course/create_order",
    method: "post",
    data
  });
}
// 课程推荐
export function courseHot(data) {
  return request({
    url: "api/v1_0_0.course/hot",
    method: "post",
    data
  });
}

// 导入作业
export function import_work(data) {
  return request({
    url: "api/v1_0_0.course/import_work",
    method: "post",
    data
  });
}

// 添加学习课程日志
export function study_log(data) {
  return request({
    url: "api/v1_0_0.course/study_log",
    method: "post",
    data
  });
}

// 获取该课程的所有老师信息
export function getCourseTeachers(data) {
  return request({
    url: "api/v2_0_0.course/getCourseTeachers",
    method: "post",
    data
  });
}

// 发布课程
export function updateCourse(data) {
  return request({
    url: "/xapi/course.course/updateField",
    method: "POST",
    data:data,
  });
}





