import request from "@/Utils/api"
// 订单创建
export function orderCreate (data) {
  return request({
    url: "api/v1_0_0.order/create",
    method: "post",
    data,
  })
}

// 调起支付
export function orderPay (data) {
  return request({
    url: "api/v1_0_0.order/pay",
    method: "post",
    data,
  })
}

// 我要反馈
export function feedbackSubmit (data) {
  return request({
    url: "api/v1_0_0.feedback/submit",
    method: "post",
    data,
  })
}
//比赛提交答题信息
export function otherAnswer (data) {
  return request({
    url: "api/v1_0_0.question_list/answer",
    method: "post",
    data
  })
}

//题单筛选
export function course (data) {
  return request({
    url: "api/v1_0_0.course/config",
    method: "post",
    cache:true,
    data
  })
}

//题单筛选
export function params (data) {
  return request({
    url: "api/v1_0_0.question_list/list_params",
    method: "post",
    cache:true,
    data
  })
}

//题目筛选
export function options (data) {
  return request({
    url: "api/v1_0_0.question/options",
    method: "post",
    cache:true,
    data
  })
}
//比赛考试作业
export function otherOptions (data) {
  return request({
    url: "api/v1_0_0.question_other/options",
    method: "post",
    cache:true,
    data
  })
}

//团队
export function team (data) {
  return request({
    url: "api/v1_0_0.user_group/list_params",
    method: "post",
    cache:true,
    data
  })
}
// 论坛
export function forum (data) {
  return request({
    url: "api/v1_0_0.forum/types",
    method: "post",
    cache:true,
    data
  })
}
// 轮播图位置 2首页 3课程 4题单 5题目 6比赛·考试·作业 7团队 8论坛
export function carousel (data) {
  return request({
    url: "api/v1_0_0.index/get_imgs",
    method: "post",
    cache:true,
    data
  })
}
// 查询订单状态
export function orderQuery (data) {
  return request({
    url: "api/v1_0_0.order/query",
    method: "post",
    data
  })
}
// 获取底部信息
export function floor (data) {
  return request({
    url: "api/v1_0_0.index/floor",
    method: "post",
    cache:true,
    data
  })
}










