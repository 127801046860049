<template>
  <div>
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <el-page-header @back="$handleRoute({course_id: id, tabName: $route.query.tabName,tid: courseMsg.tid || '' } , 'courseDetail')" :content="title"> </el-page-header>
      <div class="box">
        <div class="box-left">
          <!-- <el-collapse v-model="activeNames" @change="handleChange" v-for="(v, i) in course" :key="i"> -->
          <!-- <el-collapse-item :title="v.name" :name="v.id"> -->
          <!-- <div :class="[ -->
          <!-- 'article-item', -->
          <!-- j.id === activeID ? 'article-item-activity' : '', -->
          <!-- ]" @click="itemClick(j)" v-for="(j, k) in v._list" :key="k"> -->
          <!-- {{ j.name }} -->
          <!-- </div> -->
          <!-- </el-collapse-item> -->
          <!-- </el-collapse> -->
          <el-menu
              :default-active="showTitleId"
              class="el-menu-vertical"
              active-text-color="#ffffff">
            <div v-for="(v, i) in showText.titles" :key="i">
              <el-menu-item @click="item2Click(v.id)" :index="v.id+'-1'" v-if="(v._list || []).length == 0">{{v.title}}</el-menu-item>
              <el-submenu :index="v.id+'-1'" v-if="(v._list || []).length > 0">
                <span slot="title">{{v.title}}</span>
                <el-menu-item @click="item2Click(j.id)" :index="j.id+'-1'" v-for="(j, k) in v._list" :key="k">{{ j.title }}</el-menu-item>
              </el-submenu>
            </div>
          </el-menu>
          <!-- <el-collapse v-model="activeNames" @change="handleChange" v-for="(v, i) in showText.titles" :key="i"> -->
          <!-- <el-collapse-item :title="v.title" :name="v.id"> -->
          <!-- <div :class="[ -->
          <!-- 'article-item', -->
          <!-- j.id === showTitleId ? 'article-item-activity' : '', -->
          <!-- ]" @click="item2Click(j.id)" v-for="(j, k) in v._list" :key="k"> -->
          <!-- {{ j.title }} -->
          <!-- </div> -->
          <!-- </el-collapse-item> -->
          <!-- </el-collapse> -->
        </div>
        <div class="box-right">
          <div class="name">{{ showText.name }}</div>
          <div v-html=" showText.points" class="cont"></div>
        </div>
      </div>
    </div>
    <Payment ref="Payment" :order_num="order_num" @successPay="successPay" :mobile="mobile"  :price="price"></Payment>
  </div>
</template>

<script>
import $ from 'jquery'
import { orderCreate } from "@/api/common.js"
import { courseDetail,courseInfoList } from "@/api/course.js"
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.vue"
import Payment from "@/components/Payment/Payment.vue"
export default {
  components: {
    Breadcrumb, Payment
  },
  data () {
    return {
      price:'',
      order_num: '',
      mobile:'',
      title: "",
      activeNames: [],
      activeID: "",
      id: "",
      course: [],
      showText: {
        name: "",
        points: "",
        noShow:true,
        titles:[]
      },
      showTitleId:'',
      courseMsg: {},
    }
  },
  created () {

    this.id = this.$route.query.id;
    this.getDetail();
    this.getInfo(this.$route.query.activeID);
  },

  methods: {
    handleChange (activeNames) {
    },
    itemClick (j) {
      //未购买
      if (this.courseMsg.is_buy == 0 && j.is_try_see == 0) {
        this.confirmPop()
        return
      }
      this.getInfo(j.id);
    },
    getDetail(){
      courseDetail({ id: this.id }).then(({ data }) => {
        if (data.lists.length) {
          this.course = data.lists
          this.courseMsg = data.course
          this.title = data.course.course_name
          //没有unit_id就打开第一个
          if (!(this.$route.query.unit_id && this.$route.query.activeID)) {
            this.activeNames.push(this.course[0].id)
            this.getInfo(this.course[0]._list[0].id);
          } else {
            this.activeNames.push(Number(this.$route.query.unit_id))
          }
        }
      })
    },
    item2Click(i){
      this.showTitleId = i+'-1';
      var h = $('.box .box-right .cont [m-title="'+i+'"]').last();
      var x = h.offset() || {}
          , st = $('.box .box-right .cont').scrollTop()
          , t = st + (x.top - ($('.box .box-right .cont').offset() || {}).top);
      if(t >= 0)$('.box .box-right .cont').scrollTop(t);
    },
    getInfo(list_id){
      if(!list_id) return false;
      this.activeID = list_id;
      courseInfoList({list_id:list_id}).then(({ data }) => {
        if (!data.noShow) {
          this.confirmPop()
          return
        }
        this.showText = data;
        setTimeout(function(){
          $('.box [c-title]').click(function(){
            var i = $(this).attr('c-title');
            his.item2Click(i);
          });
        },100);
      });
    },
    successPay () {
      window.location.href = this.down_url
    },
    confirmPop () {
      this.$confirm("您还未购买该课程，是否需要购买？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            //创建订单
            orderCreate({
              type: 2,
              id: this.id,
            }).then((res) => {
              this.$refs.Payment.show = true
              this.mobile=res.data.order.mobile
              this.order_num = res.data.order.order_num
              this.price=res.data.order.price
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu-vertical{
  width:100%;
  border: none;

  .el-submenu .el-menu-item,.el-menu-item, .el-submenu__title{
    height: 32px;
    line-height: 32px;
  }
}
.box {
  ::v-deep {
    .el-collapse-item__content {
      padding-bottom: 10px;
    }
  }
  margin-top: 1rem;
  background: #ffffff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
  border: 0px solid #979797;
  height: calc(100vh - 11rem);
  display: flex;
  .box-left {
    flex: 2;
    border-right: #979797 1px solid;
    padding: 2rem 1rem;
    overflow-y:scroll;

    .article-item {
      cursor: pointer;
      padding: 0.3rem;
      margin: 0 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .article-item-activity {
      background-color: #00957e;
      color: #ffffff;
    }
  }
  .box-right {
    flex: 8;
    padding: 1rem 3rem;
    .name {
      line-height: 3rem;
      border-bottom: 1px solid #979797;
      font-size: 18px;
      font-weight: 600;
      color: #2e2e2e;
      margin-bottom: 1rem;
    }
    .cont{
      overflow-y:scroll;
      height: calc(100% - 3rem);
    }
  }
}
</style>