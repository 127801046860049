<template>
  <div class="Payment">
    <el-dialog title="" :visible.sync="show" width="500px" :show-close="true" top="20vh" @open="open" @close="close">

      <div class="selectPay" v-if="!payShow" v-loading="pay_loading">
        <div class="box1">

          <div>
            <div class="order-msg">
              <p>订单信息</p>
            </div>
            <div>
              <el-row>
                <el-col :span="14">
                  <p>订单编号：{{order_num}}</p>
                </el-col>
                <el-col :span="10">
                  <p>订单价格：{{price}}元</p>
                </el-col>
              </el-row>
            </div>
           
          </div>
          <el-divider></el-divider>
          <!-- 支付方式 -->
          <div class="paymentMode">
            <div class="title">
              <span></span>
              <p>确认支付方式</p>
            </div>
            <div class="pamentChoice">
              <div class="phone">
                <p>手机号</p>
                <el-input placeholder="请输入手机号" v-model="phone" size="small" style="width:50%;" maxlength="11"></el-input>
              </div>

              <div class="pay">
                <p>支付方式</p>
                <div class="payS">
                  <el-radio v-model="radioValue" label="微信">
                    <el-image style="width: 40px; height: 40px;" :src="
                      require('@/assets/images/me/wx.png')
                    "></el-image>
                  </el-radio>
                  <el-radio v-model="radioValue" label="支付宝">
                    <el-image style="width: 40px; height: 40px; " :src="
                      require('@/assets/images/me/zfb.png')
                    "></el-image>
                  </el-radio>
                </div>

              </div>
              <div class="remarks">
                <p>我要留言</p>
                <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="remarks">
                </el-input>
              </div>
            </div>
          </div>

          <div class="affirm-pay">
            <div class="button-primary" :style="{ padding:'4px 20px'}" @click="affirm_pay">确认支付</div>
          </div>
        </div>
      </div>

      <div class="box" v-loading="loading" v-if="payShow">
        <div>扫一扫付款</div>
        <div class="money">{{product.product.price}}元</div>
        <div class="qrcode">
          <el-image style="width: 155px;height:155px;" :src="product.pay_img"></el-image>

          <div class="tag">
            <el-image style="width: 38px;height:38px;" :src="require('@/assets/images/problemList/扫一扫@2x.png')"></el-image>
            <div>
              <div>打开手机</div>
              <div>扫一扫付款</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  orderPay, orderQuery
} from "@/api/common.js"
export default {
  components: {},
  props: {
    order_num: {
      default: () => ''
    },
    mobile: {
      default: () => ''
    },
    price:{
      default: () => 0
    }
  },
  data () {
    return {
      radioValue: '微信',

      //   备注留言
      remarks: "",
      phone: '',
      loading: false,
      show: false,
      product: {
        product: {
          price: 0
        }
      },
      timer: null,
      payShow: false,
      pay_loading: false
    }
  },
  methods: {
    affirm_pay () {
      if (!this.phone || this.phone.length < 11) {
        this.$message.error('手机号格式错误')
        return
      }
      this.loading = true
      this.loading = true;
      this.$http.post('/xapi/com.order/pay' ,{
        order_num: this.order_num,
        pay_type: this.radioValue == '微信' ? 1 : 2,
        remark: this.remark,
        mobile: this.phone
      }).then(({msg,data})=>{
        this.payShow = true
        this.product = data
        this.timer = setInterval(() => {
          this.getOrderQuery()
        }, 800)
      }).finally(()=>{
        this.loading = false;
      });
    },
    open () {
      this.phone = this.mobile
    },
    getOrderQuery () {
      orderQuery({
        order_num: this.order_num
      }).then(res => {
        if (res.data.is_pay) {
          this.$message({
            message: "支付成功",
            type: "success",
          })
          this.pay_loading = false
          clearInterval(this.timer)
          this.$parent.successPay()
          this.show = false
        }
      }).finally(() => {
        this.loading = false
      })
    },
    close () {
      this.payShow = false
      this.pay_loading = false
      clearInterval(this.timer)
    }
  },
};
</script>

<style lang="less" scoped>
.order-msg{
  margin-bottom: 1rem;
  font-weight: 600;
}
.Payment {
  .affirm-pay {
    text-align: right;
    margin-top: 2rem;
  }
  .box {
    text-align: center;
    padding: 2rem 3rem 3rem;
    font-size: 20px;
    .money {
      margin: 0.8rem 0;
    }
    .qrcode {
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      padding: 2rem 0rem;
      .tag {
        font-size: 16px;
        display: flex;
        justify-content: center;
        text-align: left;
        align-items: center;
        margin-top: 1rem;
        > div {
          margin-left: 0.5rem;
        }
      }
    }
  }
  ::v-deep {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  .box1 {
    background-color: #fff;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
    padding: 2rem;
    ::v-deep {
      .el-radio {
        display: flex;
        align-items: center;
      }
    }
    .payS {
      display: flex;
      align-items: center;
      margin-left: 2rem;
    }
    .course {
      .course_info {
        height: 100%;
        margin: 1.5% 0 0 1%;
        padding-bottom: 4.4%;
        border-bottom: 1px solid #979797;

        display: flex;
        justify-content: space-between;
        .course_info_left {
          display: flex;

          .img {
            width: 27%;
          }
          .course_info_name {
            margin-left: 2.5%;
            p {
              &:nth-child(1) {
                font-size: 36px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303030;
                line-height: 50px;
              }
              &:nth-child(2) {
                margin-top: 6%;

                span {
                  font-size: 21px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  line-height: 29px;
                  &:nth-child(1) {
                    color: #303030;
                  }
                  &:nth-child(2) {
                    color: #e94c32;
                  }
                }
              }
            }
          }
        }
        .course_info_right {
          margin-top: 3%;
          div {
            width: 88px;
            height: 30px;
            background: #d8d8d8;
            border-radius: 6px;
            padding: 0 1rem;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
            line-height: 30px;
            background: #d8d8d8;
            box-sizing: border-box;
          }
        }
      }
    }
    .phone {
      padding-top: 1.8%;

      .phone_info {
        margin: 4% 0 0 1.2%;
        padding-bottom: 2%;
        display: flex;
        border-bottom: 1px solid #979797;

        .phoneCode {
          p {
            &:nth-child(1) {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #303030;
              line-height: 22px;
            }
            &:nth-child(2) {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #828282;
              line-height: 17px;
              text-align: right;
              margin-top: 7.5%;
            }
          }
        }
        .editPhone {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e94c32;
          line-height: 22px;
          margin-left: 3.2%;
        }
      }
    }
    .paymentMode {
      padding-top: 1.8%;
      .title{
        font-weight: 600;
      }
      .pamentChoice {
        margin: 3.4% 0 0 1.2%;
        .pay {
          display: flex;
          align-items: center;
          margin-bottom: 2%;

          .imgs {
            display: flex;
            margin-left: 3%;
            .img {
              display: flex;
              align-items: center;
              margin-right: 35%;
            }
          }
        }
        .remarks {
          display: flex;
          ::v-deep {
            .el-textarea {
              margin-left: 3%;
            }
            .el-textarea__inner {
              // width: 60%;
            }
          }
        }
        .pay,
        .remarks {
          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
            line-height: 20px;
            white-space: nowrap;
          }
        }
        .phone {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          p {
            margin-right: 2.2rem;
          }
        }
      }
    }
    .order {
      padding-top: 1.8%;

      .order_info {
        margin: 2% 0 1% 1%;
        display: flex;
        justify-content: space-between;

        .order_left {
          p {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
            line-height: 22px;
            margin-bottom: 14%;
            display: flex;
            align-items: center;
          }
        }
        .order_right {
          display: flex;
          align-items: flex-end;
          p {
            margin-right: 30px;

            span {
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              line-height: 33px;
              &:nth-child(1) {
                color: #2f2f2f;
              }
              &:nth-child(2) {
                color: #e94c32;
              }
            }
          }
          div {
            width: 96px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #8d8d8d;
            border-radius: 2px;
            font-size: 21px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>